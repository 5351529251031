define(function() {
  var surveyList = function() {
    var comp = {};

    var _config = {
      selectors: {
        container: '.surveyList_headerCreditContainer',
        content: '.surveyList_headerCreditContent',
        link: '.surveyList_headerCreditLink',
        exit: '.surveyList_headerCreditLinkExit'
      },
      attribs: {
        show: 'data-show'
      }
    };

    var _init = function(el) {
      comp.element = el;
      comp.container = el.querySelector(comp.config.selectors.container);
      comp.content = el.querySelector(comp.config.selectors.content);
      comp.exit = el.querySelector(comp.config.selectors.exit);
      comp.link = el.querySelector(comp.config.selectors.link);

      comp.exit.addEventListener('click', comp.toggle);
      comp.link.addEventListener('click', comp.toggle);
    };

    var _toggle = function() {
      var isShown = comp.container.getAttribute(comp.config.attribs.show);
      comp.container.setAttribute(comp.config.attribs.show, isShown === 'true' ? 'false' : 'true');
    };

    comp.init = _init;
    comp.config = _config;
    comp.toggle = _toggle;

    return comp;
  };

  return surveyList;
});